@import "variables";

.main {
  height: 990px;
  position: relative;
  padding-top: 10.5rem;
  width: 100%;
  background-image: url("../assets/banner.webp");
  background-size: cover;
  overflow: hidden;
  background-position: -110px -110px;

  @media (min-width: 1600px) {
    height: 1240px;
  }

  @media (max-width: 1366px) {
    background-position: -180px -54px;
  }

  @media (max-width: 1150px) {
    height: 970px;
  }

  @media (max-width: 700px) {
    height: 800px;
    padding-top: 8.75rem;
    justify-content: center;
  }

  @media (max-width: 550px) {
    background-position: 0 0;
    background-image: url("../assets/banner-mobile.webp");
  }

  &__info {
    @media (max-width: 700px) {
      width: 100%;
      text-align: center;
    }
  }

  &__title {
    text-shadow: 4px 4px 0 0 #00000029;
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 3rem;

    @media (max-width: 700px) {
      font-size: 1.25rem;
    }
  }

  &__button {
    box-shadow: 0 4px 64px 0 #00000052;
  }

  &__drone {
    position: absolute;
    right: 0;
    top: 200px;
    z-index: 100;

    @media (min-width: 1600px) {
      top: 400px;
    }

    @media (max-width: 1366px) {
      top: 300px;
      width: 800px;
    }

    @media (max-width: 1150px) {
      top: 390px;
      width: 720px;
    }

    @media (max-width: 900px) {
      width: 650px;
      top: 450px;
    }

    @media (max-width: 700px) {
      width: 550px;
      top: 400px;
    }

    @media (max-width: 550px) {
      display: none;
    }

    &--mobile {
      position: absolute;
      display: none;
      z-index: 100;
      bottom: -20px;

      @media (max-width: 550px) {
        display: block;
      }
    }
  }

  &__bottom {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
  }
}
