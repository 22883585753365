@import "variables";

.consultation {
  background: $yellow;
  position: relative;

  @media (max-width: 1045px) {
    align-items: center;
  }

  @media (max-width: 550px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &:before {
    content: '';
    width: 100%;
    height: 60px;
    top: -59px;
    left: 0;
    position: absolute;
    background-image: url("../assets/consultation-background.svg");
    background-size: cover;
    z-index: 100;
  }

  &:after {
    content: '';
    width: 100%;
    height: 80%;
    bottom: 0;
    left: 0;
    position: absolute;
    background-image: url("../assets/consultation-filter.svg");
    background-size: cover;
  }

  .container {
    padding-top: 2rem;
    padding-bottom: 5rem;
  }

  &__farmer {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 200;

    @media (max-width: 1045px) {
      display: none;
    }
  }

  &__form-wrapper {
    position: relative;
    z-index: 500;
    padding-left: 30%;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 1045px) {
      padding-left: 0;
      align-items: center;
    }

    @media (max-width: 1045px) {
      padding-left: 0;
      align-items: center;
      text-align: center;
    }
  }

  &__title {
    color: $black;
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-size: 1.25rem;
    font-weight: 400;
    color: $yellow1;
    margin-bottom: 1.25rem;

    @media (max-width: 1045px) {
      text-align: center;
    }
  }

  &__form {
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  &__fields {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 3rem;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
  }

  &__label {
    color: $yellow2;
    font-weight: 400;
    padding-left: 1rem;
    padding-bottom: 0.25rem;
    text-align: left;
  }

  &__input {
    width: 280px;
    height: 54px;

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  &__button {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    box-shadow: 0 4px 64px 0 #C4A214;

    @media (max-width: 800px) {
      width: 100%;
    }
  }
}
