@import "variables";

.about-us {
  position: relative;
  overflow: hidden;
  background-color: #0C3311;
  padding-bottom: 3.75rem;

  @media (max-width: 550px) {
    padding-top: 2rem;
  }

  .container {
    margin-bottom: 8.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 850px) {
      margin-bottom: 2rem;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__subtitle {
    color: $grey;
    font-size: 1.125rem;
    text-align: center;
  }

  &__title, &__subtitle {
    position: relative;
    z-index: 100;
  }

  &__drone, &__drone-bg {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  &__drone {
    z-index: 100;
    max-width: 591px;
    transform: translate(-52%, -10%);

    @media (max-width: 1280px) {
      width: 40%;
      transform: translate(-52%, 0%)
    }

    @media (min-width: 850px) and (max-width: 1150px) {
      width: auto;
      opacity: 0.2;
      z-index: 2;
    }

    @media (max-width: 850px) {
      position: relative;
      z-index: 100;
      transform: none;
      left: auto;
      right: auto;
      width: 450px;
    }

    @media (max-width: 500px) {
      width: 300px;
    }

    @media (max-width: 350px) {
      width: 100%;
    }
  }

  &__drone-bg {
    max-width: 1127px;
    transform: translate(-52%, -35%);

    @media (min-width: 850px) and (max-width: 1150px) {
      width: auto;
      opacity: 0.3;
      z-index: 1;
    }

    @media (max-width: 850px) {
      opacity: 1;
      top: auto;
      bottom: 0;
      width: 600px;
      transform: translate(-50%, 0);
    }

    @media (max-width: 500px) {
      width: 500px;
      transform: translate(-50%, 8%);
    }

    @media (max-width: 350px) {
      width: 350px;
      transform: translate(-50%, -3%);
    }
  }

  &__cards {
    position: relative;
    width: 100%;
    max-width: 70.75rem;
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    margin: 4.75rem auto;

    @media (max-width: 850px) {
      gap: 2rem;
      margin: 2rem 0;
    }
  }

  &__cards-row {
    display: flex;
    justify-content: space-between;

    @media (max-width: 850px) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__card {
    width: 328px;
    height: 175px;
    border: 2px dashed #FFFFFF66;
    border-radius: 1rem;
    z-index: 50;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1150px) {
      background-color: rgba(12, 51, 17, 0.7);
    }

    @media (max-width: 900px) {
      padding: 1rem;
    }

    @media (max-width: 850px) {
      width: 100%;
    }

    &-title {
      color: $green;
      font-size: 3rem;
      font-weight: 800;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    &-hint {
      font-size: 1.125rem;
      font-weight: 500;
      text-align: center;
    }
  }
}
