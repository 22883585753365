@import "variables";

.burger {
  display: none;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  z-index: 2000;

  @media (max-width: 900px) {
    display: block;
  }

  &__bar {
    width: 24px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    transition: all 0.3s ease-in-out;

    &:nth-of-type(2) {
      top: calc(50% - 9px);
    }

    &:nth-of-type(3) {
      top: calc(50% + 9px);
    }
  }
}

.burger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 1.5rem 2rem;
  background: rgba(35, 35, 35, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.48);
  transform: translateX(-105%);
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1000;

  @media (max-width: 550px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &--active {
    transform: translateX(0);
  }

  &__close {
    font-size: 1.5rem;
    background-color: transparent;
    color: $white;
    outline: none;
    box-shadow: none;
    border: none;
    padding: 1rem;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    gap: 2rem;
    padding: 0 1rem;
  }

  &__link {
    font-size: 1.375rem;
    font-weight: 400;
    color: $white;
    text-decoration: none;
  }

  .contacts {
    padding: 0 1rem;
    margin-top: 2rem;

    &__button {
      margin-top: 2.5rem;
    }
  }
}
