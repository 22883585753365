@import "variables";

.services {
  color: $black;
  padding: 4.5rem 0;

  $use-cases: &;

  &__title {
    text-align: center;
    margin-bottom: 2rem;

    .highlighted {
      color: $green;
    }
  }

  &__subtitle {
    font-size: 1.375rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 4rem;
  }

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__card {
    display: flex;
    flex-direction: column;
    width: calc(25% - 1rem);
    padding: 1rem;
    box-shadow: 0 4px 32px 0 #35353514;
    border-radius: 1rem;

    &:hover {
      box-shadow: 0 4px 32px 0 #70707052;

      #{$use-cases}__card-button {
        color: $white;
        box-shadow: 0 4px 64px 0 #F8CF277A;

        &:hover {
          background-color: $yellow;
        }
      }
    }

    @media (max-width: 1200px) {
      width: calc(50% - 0.5rem);
    }

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  &__card-image {
    margin-bottom: 1.5rem;
  }

  &__card-title {
    margin-bottom: 1.5rem;
    font-size: 1.125rem;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }

  &__card-text {
    font-weight: 400;
    text-align: center;
    flex-grow: 1;
    min-height: 8.75rem;
  }
}
