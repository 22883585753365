@import "variables";
.footer {
  background: $gray1;
  height: 30.25rem;

  @media (max-width: 800px) {
    height: auto;
  }

  .container {
    display: flex;
    flex-direction: row;
    gap: 4.5rem;
    height: 100%;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      padding: 0;
      gap: 0;
    }
  }

  &__logo {
    width: 20.3rem;
    margin-bottom: 2rem;

    @media (max-width: 800px) {
      width: 100%;
      max-width: 20.3rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 800px) {
      padding: 2rem 1rem;
      align-items: center;
    }
  }

  &__contacts {
    @media (max-width: 800px) {
      align-items: center;
    }
  }

  &__map {
    flex-grow: 1;
    overflow: hidden;

    @media (max-width: 800px) {
      height: 320px;
    }

    &-iframe {
      width: 100%;
      height: 100%;
    }
  }
}
