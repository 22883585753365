@import "variables";

.header {
  position: sticky;
  margin-bottom: -6rem;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(35, 35, 35, 0.4);
  transition: 500ms;
  backdrop-filter: blur(10px);

  .container {
    height: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 14rem;

    @media (max-width: 500px) {
      width: 10rem;
    }
  }

  &__nav-wrapper {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    @media (max-width: 900px) {
      display: none;
    }

    &-link {
      text-decoration: none;
      color: $white;

      &:hover {
        text-decoration: underline;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      border-radius: 1rem;
      background: $yellow;
      outline: none;
      border: none;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      color: $black;

      &:hover {
        box-shadow: 0 4px 32px 0 #70707052;

        svg {
          path {
            fill: $white;
          }
        }
      }

      svg {
        width: 1rem;

        path {
          fill: $black;
        }
      }
    }
  }
}
